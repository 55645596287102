import React from 'react'
import Hero from '../../components/hero/hero';
import classes from './about.module.css';
import pic from './YPSA team.png';
import { useWindowsize } from '../../hooks/useWindowSize';


const About = () => {

    
  const TEAM = [
    {img: '/dima.png', name: 'Dima Al-Quzwini', position: 'CEO', description: 'Dima Al-Quzwini is a high school senior at West Windsor Plainsboro High School South interested in international relations and policy. She joined YPSA to give students the opportunity to discover their voice in a welcoming and comfortable environment. In her free time, she enjoys listening to music, rewatching Suits, and rereading the Harry Potter books'},
    {img: '/shritan.jpg', name: "Shritan Gopu", position: "CTO", description: 'Shritan Gopu is a current senior at West Windsor-Plainsboro High School South who intends to go into computer engineering and robotics. Public speaking inspired Shritan to acheive more than he currently could and used YPSA to share his experience with up and coming students. You can find him usually playing tennis, working on his racing skills, or eating too much.'},
    {img: '/rohit.png', name: 'Rohit Datta', position: 'COO', description: 'Rohit Datta is a high school senior at West Windsor-Plainsboro High School South intersted in government and public policy. By joining YPSA, he hopes to help the next generation prosper in public speaking. When not in YPSA, you can catch him spending time with his beloved dog.'}, 
    {img: '/amritha.jpeg', name: 'Amrita Choulur', position: 'Director of Marketing', description: 'Amrita Choulur is a senior at WWP South. She participates and loves public speaking for instance she is involved in her model united nations team. She joined YPSA since she wants to teach kids about a skill that she is passionate about and wants to help them grow their confidence through public speaking just like she did.'},
    {img: '/swara.jpg', name:"Swara Sanghvi", position:"Director of Outreach", description: 'Swara Sanghvi is a junior at WWP High School South. She began her public speaking journey by joining Model United Nations and Junior States of America teams. Swara was able to further her skills and hopes to help other growing speakers do the same.'}, 
    {img: '/yash.jpg', name:"Yash Tripathi", position:"Director of Recruitment", description: 'Yash Tripathi is a junior at West Windsor-Plainsboro High School South with an interest in business. He joined YPSA to give children opportunities to grow their public speaking skill and confidence in both their lives and future careers.'}

    ];

  const [width, height] = useWindowsize();

  return (
    <div>
      <div className={classes.intro}>
        <h1>Meet The Team Behind It All</h1>
        <img src={pic} alt="something"/>
      </div>

      <div className={classes.people}>
        <ul>
            {TEAM.map((value) => {
              return (
                <li>
                        <Hero className={classes.information}>
                            <img src={ "./pics" + value.img} alt={value.name}/>
                            <h2>{value.name}</h2>
                            <h3>{value.position}</h3>
                            <p>{value.description}</p>
                        </Hero>
                    </li>
                )
              })}
        </ul>
      </div>  
        {/* <Footer/> */}
    </div>
  );
}

export default About;